import { tv } from 'tailwind-variants';

export const cartItemListingTailwindVariant = tv(
  {
    slots: {
      base: ['cartItemListing'],
      headingWrapper: ['py-desk-padding-micro-x', 'bg-color-background-light'],
      headingTitle: [
        'text-heading-desk-small-bold',
        'font-heading-desk-small-bold',
        'leading-heading-desk-small-bold',
      ],
      productList: ['productList', 'rounded-[8px]', 'bg-color-background-white'],
      productCard: ['relative'],
      productWrpper: ['relative', 'border-b-[1px]', 'overflow-hidden', 'border-color-border-mid'],
      imageWrapper: [
        'h-auto',
        'w-[144px]',
        'min-w-[144px]',
        'relative',
        'pl-mob-padding-tight-x',
        'lg:pl-desk-padding-tight-x',
        'flex',
        'justify-center',
      ],
      img: ['w-auto', 'min-h-[104px]', 'h-[104px]'],
      retailMeasureWrapper: [
        'absolute',
        'bottom-0',
        'right-[-20%]',
        'p-desk-global-spacing-spacing-05',
        'flex',
      ],
      retailMeasure: [
        'bg-color-brand-primary-1-base',
        'px-desk-global-spacing-spacing-1',
        'text-body-small-bold',
        'font-body-small-bold',
        'leading-body-small-bold',
        'relative',
        'text-white',
      ],
      priceWrapper: [''],
      leftContent: [''],
      rightContent: [''],
      productDetail: [''],
      h3: [
        'text-body-large-bold',
        'no-underline',
        'hover:no-underline',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      listTotal: ['w-fit'],
      memberTotal: ['w-fit'],
      favoriteIcon: [''],
      quantityErrorWrapper: [''],
      quantityWrapper: [
        'w-fit',
        'border-[1px]',
        'border-color-border-mid',
        'rounded-[4px]',
        'items-center',
        'text-heading-desk-large-regular',
        'font-heading-desk-large-regular',
        'leading-heading-desk-large-regular',
      ],
      quantityInputWrapper: [
        'px-[16.5px]',
        'max-w-[99px]',
        'gap-[10px]',
        'items-center',
        'overflow-hidden',
      ],
      quantityInput: [
        'text-heading-desk-small-regular font-heading-desk-small-regular leading-heading-desk-small-regular',
        'w-fit',
        'outline-0',
        'max-w-[41px]',
        'overflow-hidden',
      ],
      quantityMainWrapper: [''],
      quantityButtonWrapper: [
        'flex-col',
        'bg-color-background-light',
        // 'px-[11px]',
        // 'py-3',
        'w-[31px] h-[60px]',
        'rounded-sm',
      ],
      productQtyButton: ['w-full h-1/2 text-[17px] font-[700]'],
      removeWrapper: ['mt-[20px] lg:mt-desk-margin-loose-right'],
      removeLink: ['gap-desk-space-between-base-vertical', 'flex-col'],
      productTitleWrapper: ['gap-[53px]'],
      cartNumberWrapper: ['flex', 'items-center'],
      colSpan4: ['col-span-4'],
      cartText: [
        'font-heading-desk-xLarge-bold',
        'font-primary',
        'leading-heading-desk-xLarge-bold',
        'text-heading-desk-xLarge-bold',
        'text-color-text-black',
      ],
      cartNumber: [
        'text-body-large-regular',
        'leading-body-large-regular',
        'font-body-large-regular',
        'flex',
        'gap-[3px]',
      ],
      heartIcon: [
        '[&>svg>*>*]:stroke-color-brand-primary-1-base [&>svg>*]:stroke-color-brand-primary-1-base [&>svg>*>*]:fill-color-brand-primary-1-base',
        '[&>svg]:min-w-[30px] ',
      ],
      qtyLabel: [
        'text-heading-desk-small-regular font-heading-desk-small-regular leading-heading-desk-small-regular',
      ],
      retaiIcon: ['[&>svg>*]:fill-color-brand-primary-1-base'],
    },
    compoundSlots: [
      {
        slots: [
          'removeLink',
          'productWrpper',
          'productDetail',
          'quantityButtonWrapper',
          'quantityWrapper',
          'removeWrapper',
          'quantityMainWrapper',
          'productTitleWrapper',
          'quantityInputWrapper',
        ],
        class: ['flex'],
      },
    ],
    variants: {
      size: {
        mobile: {
          cartNumberWrapper: ['gap-mob-margin-base-right'],
          headingWrapper: ['hidden'],
          productCard: ['p-mob-padding-micro-y'],
          productWrpper: [
            'pr-mob-padding-micro-x',
            'py-mob-padding-micro-y',
            'gap-mob-margin-loose-bottom',
          ],
          listTotal: ['mt-mob-margin-loose-bottom'],
          memberTotal: ['mt-mob-space-between-base-vertical'],
          productDetail: ['flex-col'],
          quantityWrapper: ['mt-mob-margin-loose-bottom'],
          removeWrapper: ['items-end', 'justify-between'],
          removeLink: ['items-start'],
          quantityMainWrapper: ['flex-col', 'justify-between'],
        },
        desktop: {
          cartNumberWrapper: ['gap-desk-margin-base-right'],
          headingWrapper: ['grid', 'grid-cols-6'],
          productCard: ['p-desk-padding-micro-y'],
          productWrpper: [
            'pr-desk-padding-tight-x',
            'py-desk-padding-tight-y',
            'gap-desk-margin-loose-bottom',
          ],
          productDetail: ['flex-col'],
          priceWrapper: [
            'mt-desk-margin-loose-right',
            'flex',
            'gap-desk-global-spacing-spacing-12',
          ],
          listTotal: ['mt-0'],
          memberTotal: ['mt-0'],
          quantityWrapper: ['mt-0'],
          removeWrapper: ['mt-0', 'items-start', 'gap-0'],
          rightContent: ['flex', 'gap-desk-margin-loose-right'],
          removeLink: ['items-center', 'gap-0'],
          quantityMainWrapper: ['flex-col justify-between'],
        },
        desktopLarge: {
          removeWrapper: ['items-start', 'mt-0'],
          quantityMainWrapper: ['flex-row', 'gap-desk-margin-loose-right'],
        },
      },
    },
  },
  { responsiveVariants: ['lg', 'xl'] }
);
